// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB0QbEV2IycOx8P9IrBy1oB6QxVOue5aws",
  authDomain: "scavenge-wars.firebaseapp.com",
  projectId: "scavenge-wars",
  storageBucket: "scavenge-wars.appspot.com",
  messagingSenderId: "1012157828720",
  appId: "1:1012157828720:web:d6f07aa3cf1d3399de2eb5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
export const auth = getAuth(app);
export const db = getFirestore(app);
