import React from 'react';


const ProfileButtons = ({ onTrophyCaseClick }) => {
    const handleTrophyCaseClick = () => {
        console.log('Trophy Case button clicked');
        onTrophyCaseClick();
    };
    return (
        <div className="flex justify-center space-x-4 my-1">
            <button className="inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                <i className="fa-solid fa-envelope"></i>
                <span className=" sm:block ml-2"> Log</span>
            </button>
            <button className="inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                <i className="fa-solid fa-screwdriver-wrench"></i>
                <span className=" sm:block ml-2">Craft</span>
            </button>
            <button 
                className="inline-flex items-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleTrophyCaseClick}
            >
                <i className="fa-solid fa-trophy-star"></i> 
                <span className=" sm:block ml-2">Trophy Case</span>
            </button>
            
        </div>
    );
};

export default ProfileButtons;
