import React, { useState, useRef, useEffect } from "react";
import items from "../utils/items.json";
import LootItem from "../profile/LootItem"; // Import the LootItem component
import { fetchUserTrophyCase } from "../../services/trophyCaseService";

const rarityColorMapping = {
    verycommon: { name: "Very Common", colorClass: "text-verycommon" },
    common: { name: "Common", colorClass: "text-common" },
    uncommon: { name: "Uncommon", colorClass: "text-uncommon" },
    rare: { name: "Rare", colorClass: "text-rare" },
    veryrare: { name: "Very Rare", colorClass: "text-veryrare" },
    epic: { name: "Epic", colorClass: "text-epic" },
    legendary: { name: "Legendary", colorClass: "text-legendary" },
    artifact: { name: "Artifact", colorClass: "text-artifact" },
    heirloom: { name: "Heirloom", colorClass: "text-heirloom" },
    assembled: { name: "Assembled", colorClass: "text-assembled" },
};

const TrophyCasePopup = ({ isOpen, onClose, uid }) => {
    const [selectedRarity, setSelectedRarity] = useState(null);
    const [userTrophyCaseItems, setUserTrophyCaseItems] = useState([]);
    const popupRef = useRef();
    //const { currentUser } = useAuth();
    useEffect(() => {
        // Fetch user's trophy case items from the database
        fetchUserTrophyCase(uid).then(setUserTrophyCaseItems);

        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        }

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, onClose, uid]);

    const handleRarityClick = (rarityKey) => {
        setSelectedRarity({
            key: rarityKey,
            readable: rarityColorMapping[rarityKey]?.name || rarityKey,
        });
    };

    const countItemsByRarity = (rarityKey) => {
        const totalItems = items.filter(
            (item) => item.rarity === rarityKey
        ).length;
        const foundItems = userTrophyCaseItems.filter(
            (tcItem) => tcItem.item.rarity === rarityKey
        ).length;
        return `${foundItems}/${totalItems}`;
    };

    const renderRaritySelector = () => {
        return (
            <div className="bg-unresearched">
                {Object.keys(rarityColorMapping).map((rarityKey, index, array) => {
                    const rarityCount = countItemsByRarity(rarityKey);
                    const rarityInfo = rarityColorMapping[rarityKey];
    
                    // Determine border classes based on position in the array
                    let borderClasses = "border-l border-r border-b";
                    if (index === 0) { 
                        borderClasses += " border-t";
                    }
    
                    return (
                        <div
                            key={rarityKey}
                            onClick={() => handleRarityClick(rarityKey)}
                            className={`cursor-pointer hover:bg-gray-200 font-semibold p-1 rounded ${rarityInfo.colorClass} ${borderClasses}`}
                        >
                            ■ {rarityInfo.name} <span className="text-white">({rarityCount})</span>
                        </div>
                    );
                })}
            </div>
        );
    };
    
    

    const renderItemsByRarity = (rarity) => {
        if (!rarity) return null; // Return null if the rarity key is not provided

        const filteredItems = items.filter((item) => item.rarity === rarity);
        return filteredItems.map((item, index) => {
            let updatedItem = { ...item }; // Copy the original item to a new object

            const foundTrophyCaseItem = userTrophyCaseItems.find(
                (tcItem) => tcItem.item.itemName === item.itemName
            );

            if (foundTrophyCaseItem) {
                updatedItem.quality = foundTrophyCaseItem.item.quality;
                updatedItem.research = foundTrophyCaseItem.item.research;
            } else {
                updatedItem.research = 0;
                updatedItem.itemName = item.itemName.replace(/\w/g, "?");
            }

            // Use a composite key combining the item's original name and rarity
            const key = `${item.itemName}-${item.rarity}-${index}`;

            return (
                <div className="bg-unresearched text-left" key={key}>
                    <LootItem
                        item={{ ...updatedItem }}
                        onSelectItem={() => {}}
                        isSelected={false}
                        uid={uid}
                    />
                </div>
            );
        });
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div
                ref={popupRef}
                className="bg-amber-500 p-2 w-full rounded-md shadow-lg text-center"
                style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
                {!selectedRarity ? (
                    <div>{renderRaritySelector()}</div>
                ) : (
                    <div>
                        <div className="text-left">
                            <button
                                className="bg-transparent hover:bg-gray-100 text-gray-800 font-semibold "
                                onClick={() => setSelectedRarity(null)}
                            >
                                {"< "}Back
                            </button>
                        </div>
                        <h2 className="bg-dark-brown text-md sm:text-lg rounded-t-3xl text-center text-tan mx-0.5 py-0 sm:py-2 mt-2">
                            {selectedRarity.readable}
                        </h2>

                        {selectedRarity
                            ? renderItemsByRarity(selectedRarity.key)
                            : renderRaritySelector()}
                    </div>
                )}
                <button
                    onClick={onClose}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default TrophyCasePopup;
