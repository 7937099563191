import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { currentUser, username } = useAuth();

    // Redirect to login if no user and trying to access a route other than home
    if (!currentUser && (window.location.pathname !== "/" || window.innerWidth < 640)) {
        return <Navigate to="/login" />;
    }
    // If logged in and username is set, redirect to profile
    else if (username && window.location.pathname === "/")  {
        return <Navigate to="/profile" />;
    }
    // Redirect to set username if user exists but username is empty
    else if (currentUser && !username && window.location.pathname !== "/setusername") {
        sessionStorage.setItem('route', window.location.pathname);
        return <Navigate to="/setusername" />;
    }
    // Redirect from setusername to intended route or home
    else if (username && window.location.pathname === "/setusername") {
        const route = sessionStorage.getItem('route') || '/';
        return <Navigate to={route} />;
    }
    // Render the children components
    return children;
};

export default ProtectedRoute;
