import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useQuery } from 'react-query';
import { fetchUserProfile } from "../../services/userProfileService";
import { queryClient } from "../../index";

const MobileStatusBar = () => {
    const { currentUser, username } = useAuth();
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [countdown, setCountdown] = useState(120 - new Date().getSeconds());

    const { data: userData, isLoading, error } = useQuery(
        ['userProfile', currentUser?.uid],
        () => fetchUserProfile(currentUser?.uid),
        { enabled: !!currentUser?.uid }
    );

    useEffect(() => {
        if (!userData || userData.energy >= 20) return;
    
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown > 1) {
                    return prevCountdown - 1;
                } else {
                    queryClient.invalidateQueries(['userProfile', currentUser?.uid]);
                    return 120;
                }
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [userData, currentUser?.uid]);
    
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    const goToChat = () => {
        navigate("/chat");
    };

    const currentPageName = "Home";
    const status =
        "FancyName has attacked Ryth and stole a Q10 Interstellar Command Center";
    const newChatMessage = 22;

    return (
        <>
            {/* Mobile header with two rows */}
            {currentUser && username ? (
                <div className="sm:hidden fixed top-0 left-0 right-0 bg-light-brown border-b-2 border-gray-800 text-white z-50">
                    {/* First row for status */}
                    <div className="px-2 py-1 overflow-hidden">
                        <div
                            className="text-xs text-center whitespace-nowrap"
                            style={{ animation: "marquee 15s linear infinite" }}
                        >
                            <span>{status}</span>{" "}
                            {/* Replace with your status component */}
                        </div>
                    </div>

                    {/* Second row for page name, chat icon, and user stats */}
                    <div className="relative flex items-center px-2 py-1 pb-3">
                        <div className="absolute left-2">
                            <div className="relative text-lg " onClick={goToChat}>
                                <i className="fa-solid fa-comment fa-xl"></i>
                                <span
                                    className="absolute top-0 right-0 text-xs text-white bg-red-500 rounded-full px-1"
                                    style={{
                                        transform: "translate(50%, -50%)",
                                    }}
                                >
                                    {newChatMessage}
                                </span>
                            </div>
                        </div>

                        <div className="absolute right-2 flex text-xs">
                            <div className="flex flex-col items-end mr-1">
                                <span>{userData.totalAttack}</span>
                                <span>{userData.totalDefense}</span>
                                <span>{userData.energy}</span>
                            </div>
                            <div className="flex flex-col items-start">
                                <span>:Atk</span>
                                <span>:Def</span>
                                <span>:Eng</span>
                            </div>
                        </div>

                        {/* Centered page name with flex-grow */}
                        <div className="flex-grow text-2xl font-bold text-center">
                            <span className="drop-shadow-xl shadow-black">
                                {currentPageName}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="sm:hidden fixed top-0 left-0 right-0 bg-light-brown border-b-2 border-gray-800 text-white z-50 py-3">
                    <div className="flex-grow text-2xl font-bold text-center">
                        <span className="drop-shadow-xl shadow-black">
                           Scavenge Wars
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default MobileStatusBar;
