import React, { useState, useEffect } from "react";
import LootItem from "../profile/LootItem";
import items from "../utils/items.json";

// The different rarities
const itemsByRarity = {
    verycommon: items.filter((item) => item.rarity === "verycommon"),
    common: items.filter((item) => item.rarity === "common"),
    uncommon: items.filter((item) => item.rarity === "uncommon"),
    rare: items.filter((item) => item.rarity === "rare"),
    veryrare: items.filter((item) => item.rarity === "veryrare"),
    epic: items.filter((item) => item.rarity === "epic"),
    legendary: items.filter((item) => item.rarity === "legendary"),
    artifact: items.filter((item) => item.rarity === "artifact"),
    heirloom: items.filter((item) => item.rarity === "heirloom"),
};

// The rarity probabilities in %
const rarityConfig = {
    verycommon: 50,
    common: 25,
    uncommon: 15,
    rare: 7.5,
    veryrare: 2.2,
    epic: 0.25,
    legendary: 0.05, // 0.05%
    artifact: 0.005, // 0.005%
    heirloom: 0.001,
};

// Probability distribution for quality
const pickQuality = () => {
    const rand = Math.random() * 100;

    if (rand < 3) return 10; // Quality 10: 3%
    if (rand < 8) return 9; // Quality 9: 5% (cumulative up to 8%)
    if (rand < 15) return 8; // Quality 8: 7% (cumulative up to 15%)
    if (rand < 30) return 7; // Quality 7: 15% (cumulative up to 30%)
    if (rand < 50) return 6; // Quality 6: 20% (cumulative up to 50%)

    // Qualities 1 to 5: 50% (remaining range)
    return Math.floor(Math.random() * 5) + 1; // Randomly selects a number between 1 and 5
};

const pickItemBasedOnRarity = (itemsByRarity) => {
    const rand = Math.random() * 100; // Scale the random number to percentage
    let cumulativeProbability = 0;

    for (const rarity in rarityConfig) {
        cumulativeProbability += rarityConfig[rarity];
        if (rand < cumulativeProbability) {
            const itemsOfRarity = itemsByRarity[rarity];
            if (itemsOfRarity && itemsOfRarity.length) {
                const item = {
                    ...itemsOfRarity[
                        Math.floor(Math.random() * itemsOfRarity.length)
                    ],
                };
                item.quality = pickQuality(); // Assign quality
                item.research = 0; 
                return item;
            }
        }
    }

    // Default case for the most common items
    const item = {
        ...itemsByRarity.common[
            Math.floor(Math.random() * itemsByRarity.common.length)
        ],
    };
    item.quality = pickQuality(); // Assign quality
    item.research = 0; 
    return item;
};

const generateRandomItems = (count) => {
    const scavengedItems = [];
    for (let i = 0; i < count; i++) {
        scavengedItems.push(pickItemBasedOnRarity(itemsByRarity));
    }
    return scavengedItems;
};

const ScavengedItems = ({ isScavenging, onSelectItem, selectedScavengedItems }) => {
    const [scavengedItems, setScavengedItems] = useState([]);

    useEffect(() => {
        if (isScavenging) {
            // Generate and set random items
            const newItems = generateRandomItems(6);
            setScavengedItems(newItems);
        } else setScavengedItems([]);
    }, [isScavenging]);

    // Determine the number of empty slots needed
    const emptySlotsCount = 6 - scavengedItems.length;
    const emptySlots = Array(emptySlotsCount).fill({ itemName: "Empty Slot" });

    return (
        <div className="md:p-6 p-2 pt-0 mt-0">
            <h2 className="bg-dark-brown text-md sm:text-lg rounded-t-3xl text-center text-tan mx-0.5 py-0 sm:py-2">
                Scavenged Loot:
            </h2>
            <div className="bg-unresearched text-white">
                {scavengedItems.concat(emptySlots).map((item, index) => (
                    <LootItem
                        key={index}
                        item={item}
                        onSelectItem={() => onSelectItem(item)}
                        isSelected={Array.isArray(selectedScavengedItems) && selectedScavengedItems.includes(item)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ScavengedItems;
