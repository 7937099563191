const ScavengeButton = ({ onScavenge }) => {
    return (
        <div className="flex justify-between items-center my-1 mt-1 sm:mt-4">
            <div className="flex-grow text-center">
                <button
                    className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded-md inline-flex items-center"
                    onClick={onScavenge}
                >
                    Scavenge (1 energy)
                </button>
            </div>
        </div>
    );
};

export default ScavengeButton;
