import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AdminRoute = ({ children }) => {
    const { currentUser, username } = useAuth();

    if (!currentUser || username !== "secretadmin123") {
        return <Navigate to="/nopage" />;
    }

    return children;
};

export default AdminRoute;