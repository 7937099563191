import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { fetchUserProfile } from "../../services/userProfileService";
import { queryClient } from "../../index";

const ProfileHeader = ({ uid }) => {
    const { data: userProfile, isLoading, error } = useQuery(
        ["userProfile", uid],
        () => fetchUserProfile(uid),
        { enabled: !!uid }
    );

    const secondsRemaining = 120 - new Date().getSeconds();
    const [countdown, setCountdown] = useState(secondsRemaining);

    useEffect(() => {
        if (!userProfile || userProfile.energy >= 20) return;

        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown > 1) {
                    return prevCountdown - 1;
                } else {
                    queryClient.invalidateQueries(["profile", uid]);
                    return 120;
                }
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [userProfile, uid]);

    const getTotalAttackAndDefense = () => {
        let totalAttack = 0;
        let totalDefense = 0;
        userProfile.inventory.forEach(item => {
            totalAttack += item.attack;
            totalDefense += item.defense;
        });
        return { totalAttack, totalDefense };
    };
    const { totalAttack, totalDefense } = getTotalAttackAndDefense();
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="pb-2 md:p-4 mx-2 mt-4 md:m-6 bg-dark-brown rounded-3xl text-tan">
            <h1 className="text-xl md:text-2xl text-center text-white font-semibold mb-0 sm:mb-4">
                {userProfile.username}
            </h1>
            <div className="flex justify-between">
                <div className="flex flex-col pl-5 sm:pl-20">
                    {" "}
                    {/* Adjusted padding for small screens */}
                    <div className="mb-2">
                        <i className="fa-solid fa-swords"></i>
                        <span> Attack:</span>
                        <span> {totalAttack}</span>
                    </div>
                    <div className="mb-2">
                        <i className="fa-solid fa-shield-halved"></i>
                        <span> Defense:</span>
                        <span> {totalDefense}</span>
                    </div>
                    <div>
                        <i className="fa-solid fa-globe"></i>
                        <span> Rank:</span>
                        <span> {userProfile.ranking}</span>
                    </div>
                </div>

                <div className="flex flex-col pr-5 sm:pr-20 text-left">
                    {" "}
                    {/* Adjusted padding for small screens */}
                    <div className="mb-2">
                        <i className="fa-solid fa-bolt"></i>
                        <span> Energy:</span>
                        <span> {userProfile.energy}</span>
                        {userProfile.energy < 20 ? (
                            <span>
                                {" "}({countdown}s)
                            </span>
                        ) : (
                            " (max)"
                        )}
                    </div>
                    <div className="mb-2">
                        <i className="fa-solid fa-coins"></i>
                        <span> Gold:</span>
                        <span> {userProfile.gold}</span>
                    </div>
                    <div>
                        <i className="fa-solid fa-gift"></i>
                        <span> Cash:</span>
                        <span> {userProfile.cash}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileHeader;
