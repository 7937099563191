import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(true);

    // Function to fetch user data from MongoDB via your backend API
    const fetchUserData = async (userId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/users/${userId}`
            );

            const userData = await response.json();
            if (userData.username !== undefined) {
                setUsername(userData.username); // Set the username
            } else {
                console.log("NO");
                setUsername("");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            setCurrentUser(user);
            if (user) {
                await fetchUserData(user.uid); // Fetch and set username
            } else {
                setUsername(""); // Reset username if there's no user
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser, username }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
