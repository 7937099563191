import { useQuery, useMutation } from 'react-query';
import { queryClient } from "../../index";
import { updateUserTrophyCase } from "../../services/trophyCaseService";
import { useAuth } from '../../context/AuthContext';

const LootItemDetails = ({ item, isOpen, onClose, scavengePage }) => {
    const { currentUser, username } = useAuth();
    console.log("UID: ", currentUser.uid);
    const updateTrophyCaseMutation = useMutation(
        (item) =>
            updateUserTrophyCase(currentUser.uid, username, item),
        {
            onSuccess: () => {
                // Invalidate and refetch user data to update the local state
                queryClient.invalidateQueries(["userProfile", currentUser.uid]);
                queryClient.invalidateQueries(["inventory", currentUser.uid]);
                queryClient.invalidateQueries(["trophyCase", currentUser.uid]);
            },
            onError: (error) => {
                console.error("Error updating trophy case", error);
            },
        }
    );
    if (!isOpen) return null;

    
    // Function to add item to trophy case
    const addToTrophyCase = async () => {
        if (item.research !== 100) {
            alert("Item must be fully researched to add to the trophy case.");
            return;
        }

        // API call to add the item to the trophy case
        updateTrophyCaseMutation.mutate(item);
    };
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-black p-8 rounded-lg w-1/2 h-3/4">
                {" "}
                {/* Adjusted size here */}
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-red-500 text-white p-2 rounded"
                    >
                        Close
                    </button>
                </div>
                <div className="overflow-auto h-5/6">
                    <div className="text-red-500">
                        <h2>{item.itemName}</h2>
                        <p>Quality: {item.quality}</p>
                        <p>Attack: {item.attack}</p>
                        <p>Defense: {item.defense}</p>
                        <p>Percentage: {item.research}</p>
                        <p>Rarity: {item.rarity}</p>
                        <p>Value: {item.value}</p>
                    </div>
                </div>
                {!scavengePage && (
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={addToTrophyCase}
                            className="bg-green-500 text-white p-2 rounded"
                        >
                            Add to Trophy Case
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LootItemDetails;
