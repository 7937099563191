import { useState } from "react";
import TrashPopup from "./TrashPopup";

const SwapButton = ({ page, onSwap }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleTrashClick = () => {
      setIsPopupOpen(true); // Open the confirmation popup
  };

  const handleConfirmDelete = () => {
      // Handle the deletion logic here
      setIsPopupOpen(false); // Close the popup after confirming
  };

  return (
    <>
      <div className="flex items-center justify-center px-2 pb-2">
          <div className="flex-1"></div> {/* Left empty column */}
          <div> {/* Middle column with Swap button */}
              <button
                  className="bg-red-900 hover:bg-red-950 text-white font-bold py-2 px-4 rounded-md inline-flex items-center"
                  onClick={onSwap}
              >
                  <span className="mr-2">↑</span>
                  {page === "Scavenge" ? "Take/Swap" : "Swap (2 energy)"}
                  <span className="ml-2">↓</span>
              </button>
          </div>
          <div className="flex-1 flex justify-end"> {/* Right column with Trash button */}
             {page !== "Scavenge" &&
              (<button 
                  className="bg-stone-800 hover:bg-stone-900 text-white font-bold py-2 px-4 rounded" 
                  onClick={handleTrashClick}
              >
                  <i className="fa-solid fa-trash"></i>
              </button>)
              }
          </div>
      </div>
      <TrashPopup 
          isOpen={isPopupOpen}
          onConfirm={handleConfirmDelete}
          onCancel={() => setIsPopupOpen(false)}
      />
    </>
  );
};

export default SwapButton;
