import React, { useState } from "react";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import {
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
} from "firebase/auth";
import scavengewarslogo from "../../assets/scavengewarslogo.png";

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");

    const handleSignIn = async (e) => {
        e.preventDefault();

        let userEmail = email;
        // Check if the input is a username (not containing '@')
        if (!email.includes("@")) {
            // Check if username exists in mongodb
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/login`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: email,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            if (data.exists) {
                // Username found, retrieve email
                userEmail = data.email;
            } else {
                // Username not found
                setMessage("Invalid email/username or password.");
                setMessageType("error");
                return;
            }
        }

        signInWithEmailAndPassword(auth, userEmail, password)
            .then((userCredential) => {
                // Handle successful sign-in
                //console.log(userCredential);
                //navigate("/"); // Redirect to home or dashboard page
                navigate("/profile");
            })
            .catch((error) => {
                //console.log(error);
                setMessage("Invalid email/username or password."); // Set error message
                setMessageType("error");
            });
    };

    const goToResetPassword = () => {
        navigate("/resetpassword");
    };

    const goToSignUp = () => {
        navigate("/signup");
    };

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                navigate("/");
                //console.log(result);
            })
            .catch((error) => {
                //console.log(error);
            });
    };
    
    return (
        <div className="bg-[#F9FAFB] sm:h-screen w-screen flex items-center">
            <div className="h-max mx-auto flex flex-col items-center">
                <img
                    className="h-24 w-24 mb-5"
                    src={scavengewarslogo}
                    alt="Scavenge Wars Logo"
                />

                <h1 className="text-xl font-bold text-center">Login</h1>
                {message && (
                    <p
                        className={`mt-4 px-4 py-2 rounded-md text-center font-semibold 
                 ${
                     messageType === "success"
                         ? "bg-green-100 text-green-700"
                         : "bg-red-100 text-red-700"
                 }`}
                    >
                        {message}
                    </p>
                )}
                <form
                    className="bg-white shadow-xl p-4 sm:p-10 flex flex-col gap-4 text-sm"
                    onSubmit={handleSignIn}
                    autoComplete="off"
                >
                    <div>
                        <label
                            className="text-gray-600 font-bold inline-block pb-2"
                            htmlFor="email"
                        >
                            Email / Username
                        </label>
                        <input
                            className="border border-gray-400 focus:outline-slate-400 rounded-md w-full shadow-sm px-5 py-2"
                            type="text"
                            name="email"
                            placeholder="Enter your email or username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label
                            className="text-gray-600 font-bold inline-block pb-2"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            className="border border-gray-400 focus:outline-slate-400 rounded-md w-full shadow-sm px-5 py-2"
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    
                    <div>
                        <input
                            className="bg-[#4F46E5] w-full py-2 rounded-md text-white font-bold cursor-pointer hover:bg-[#181196]"
                            type="submit"
                            value="Login"
                        />
                    </div>
                    <div className="flex">
                        <div className="w-1/2">
                            <button
                                className="font-bold text-blue-600 hover:text-blue-700 cursor-pointer bg-transparent border-none p-0"
                                onClick={goToResetPassword}
                                type="button" // Set the type to 'button'
                                style={{ outline: "none" }}
                            >
                                Forgot password?
                            </button>
                        </div>
                    </div>
                    <div className="flex justify-center mt-4">
                        <button
                            type="button"
                            className="px-4 py-2 border flex gap-2 border-slate-400 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-900 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150"
                            onClick={googleSignIn}
                        >
                            <img
                                className="w-6 h-6"
                                src="https://www.svgrepo.com/show/475656/google-color.svg"
                                alt="google logo"
                            />
                            <span>Login with Google</span>
                        </button>
                    </div>
                </form>
                <p className="text-sm text-gray-500 mt-5">
                    Not a scavenger?{" "}
                    <span
                        onClick={goToSignUp}
                        className="text-[#4F46E5] font-bold cursor-pointer"
                    >
                        Create an account
                    </span>
                </p>
            </div>
        </div>
    );
};

export default SignIn;
