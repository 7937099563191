/******** RETRIEVE FROM DATABASE ********/

// Fetch user profile
export const fetchUserProfile = async (uid) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${uid}`);

    if (!response.ok) {
        throw new Error('Failed to fetch user profile');
    }
    return response.json();
};

// Fetch user's items
export const fetchUserItems = async (uid) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${uid}/items`);

    if (!response.ok) {
        throw new Error('Failed to fetch user items');
    } 
    return response.json();
}

// Fetch user's duffel bag
export const fetchUserDuffel = async (uid) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${uid}/duffel`);

    if (!response.ok) {
        throw new Error('Failed to fetch user duffel');
    } 
    return response.json();
}

/********** UPDATE DATABASE ********/

// Update user's energy
export const updateUserEnergy = async (uid, energy) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/updateEnergy`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: uid, energyToDeduct: energy }),
    }); 
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

// Update user's gold
export const updateUserGold = async (uid, gold) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/updateGold`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: uid, goldToIncrease: gold }),
    }); 
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

// Update user inventory
export const updateInventory = async (uid, updatedInventory) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/updateInventory`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: uid, inventory: updatedInventory }),
    }); 
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

// Update user duffel bag
export const updateDuffel = async (uid, updatedDuffel) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/updateDuffel`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: uid, duffel: updatedDuffel }),
    }); 
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};