import React, { useState } from "react";
import ProfileHeader from "../components/profile/ProfileHeader";
import InventoryList from "../components/profile/InventoryList";
import SwapButton from "../components/profile/SwapButton";
import DuffelBag from "../components/profile/DuffelBag";
import { useAuth } from "../context/AuthContext";
import ProfileButtons from "../components/profile/ProfileButtons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../index";
import {
    updateInventory,
    fetchUserProfile,
    updateDuffel,
    updateUserEnergy,
} from "../services/userProfileService";
import DuffelPopup from "../components/profile/DuffelPopup";
import TrophyCasePopup from "../components/trophycase/TrophyCasePopup";

const Profile = () => {
    //const [userData, setUserData] = useState(null);
    const { currentUser } = useAuth();
    const {
        data: userData,
        isLoading,
        error,
    } = useQuery(["userProfile", currentUser.uid], () =>
        fetchUserProfile(currentUser.uid)
    );

    const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);
    const [selectedDuffelItems, setSelectedDuffelItems] = useState([]);

    const [popupContent, setPopupContent] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [isTrophyCaseOpen, setIsTrophyCaseOpen] = useState(false);

    // Function to toggle Trophy Case popup
    const toggleTrophyCasePopup = () => {
        setIsTrophyCaseOpen(!isTrophyCaseOpen);
    };

    // MUTATIONS
    const updateInventoryMutation = useMutation(
        (updatedInventory) =>
            updateInventory(currentUser.uid, updatedInventory),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["userProfile", currentUser.uid]);
                queryClient.invalidateQueries(["inventory", currentUser.uid]);
                setSelectedDuffelItems([]);
                setSelectedInventoryItems([]);
                //setIsScavenging(false);
                console.log("SII: ", selectedInventoryItems);
            },
            onError: (error) => {
                console.error("Error updating inventory", error);
            },
        }
    );
    const updateDuffelMutation = useMutation(
        (updatedDuffel) => updateDuffel(currentUser.uid, updatedDuffel),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["userProfile", currentUser.uid]);
                queryClient.invalidateQueries(["duffel", currentUser.uid]);
                setSelectedDuffelItems([]);
                setSelectedInventoryItems([]);
                //setIsScavenging(false);
            },
            onError: (error) => {
                console.error("Error updating inventory", error);
            },
        }
    );

    const updateEnergyMutation = useMutation(
        (newEnergy) => updateUserEnergy(currentUser.uid, newEnergy),
        {
            onSuccess: (data) => {
                // Invalidate and refetch user data
                queryClient.invalidateQueries(["userProfile", currentUser.uid]);
            },
            onError: (error) => {},
        }
    );

    // Handle selection for duffel bag items
    const handleDuffelBagItemSelect = (selectedItem) => {
        if (selectedItem.itemName !== "Empty Slot") {
            console.log(selectedItem);
            setSelectedDuffelItems((prevItems) => {
                if (prevItems.includes(selectedItem)) {
                    return prevItems.filter((item) => item !== selectedItem);
                } else {
                    return [...prevItems, selectedItem];
                }
            });
        }
    };

    const handleInventoryItemSelect = (selectedItem) => {
        if (selectedItem.itemName !== "Empty Slot") {
            console.log(selectedItem);
            setSelectedInventoryItems((prevItems) => {
                if (prevItems.includes(selectedItem)) {
                    return prevItems.filter((item) => item !== selectedItem);
                } else {
                    return [...prevItems, selectedItem];
                }
            });
        }
    };

    // Handle the swap
    const handleSwap = () => {
        if (userData.energy <= 1) {
            setIsPopupOpen(true);
            setPopupContent("You do not have enough energy.");
            return;
        } else if (
            selectedDuffelItems.length === 0 &&
            selectedInventoryItems.length === 0
        ) {
            setIsPopupOpen(true);
            setPopupContent("Nothing to swap.");
            return;
        } else if (selectedInventoryItems.find((item) => item.research < 100)) {
            setPopupContent("Items must be fully researched.");
            setSelectedInventoryItems([]);
            setSelectedDuffelItems([]);
            setIsPopupOpen(true);
            return;
        }
        let updatedInventory = userData.inventory || [];
        let updatedDuffel = userData.duffelBag || [];
        console.log("Updated inventory 1: ", updatedInventory);
        console.log("Updated duffel bag 1: ", updatedDuffel);
        // Remove selected items from the inventory and add them to the duffel bag
        selectedInventoryItems.forEach((selectedItem) => {
            const index = updatedInventory.findIndex((invItem) =>
                areItemsEqual(invItem, selectedItem)
            );
            if (index !== -1) {
                updatedInventory.splice(index, 1); // Remove the first matching item
            }
        });
        updatedDuffel = [...updatedDuffel, ...selectedInventoryItems];

        // Remove selected items from the duffel bag and add them to the inventory
        selectedDuffelItems.forEach((selectedItem) => {
            const index = updatedDuffel.findIndex((invItem) =>
                areItemsEqual(invItem, selectedItem)
            );
            if (index !== -1) {
                updatedDuffel.splice(index, 1); // Remove the first matching item
            }
        });
        updatedInventory = [...updatedInventory, ...selectedDuffelItems];

        console.log("Updated inventory 2: ", updatedInventory);
        console.log("Updated duffel bag 2: ", updatedDuffel);
        // Check if the swap would exceed the inventory or duffel limit
        if (updatedInventory.length > 6) {
            setPopupContent("Inventory cannot exceed 6 items.");
            setIsPopupOpen(true);
            return;
        } else if (updatedDuffel.length > userData.duffelBagSize) {
            setPopupContent(
                "Duffel bag cannot exceed " + userData.duffelBagSize + " items."
            );
            setIsPopupOpen(true);
            return;
        }

        // Use the mutations to update the inventory and duffel
        updateInventoryMutation.mutate(updatedInventory);
        updateDuffelMutation.mutate(updatedDuffel);

        updateEnergyMutation.mutate(2);

        // Reset selected items
        setSelectedInventoryItems([]);
        setSelectedDuffelItems([]);
    };

    const areItemsEqual = (item1, item2) => {
        return item1.name === item2.name && item1.quality === item2.quality; // Example comparison
    };
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error fetching user data: {error.message}</div>;

    return (
        <div className="min-h-screen bg-gray-900 sm:p-6">
            <DuffelPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
            >
                <p>{popupContent}</p>
            </DuffelPopup>
            {isTrophyCaseOpen && (
                <TrophyCasePopup
                    uid={currentUser.uid}
                    isOpen={isTrophyCaseOpen}
                    onClose={() => setIsTrophyCaseOpen(false)}
                >
                    {/* Your popup content here */}
                </TrophyCasePopup>
            )}
            
            <div className="max-w-2xl mx-auto bg-amber-500 shadow-md sm:rounded-lg overflow-hidden pb-5">
                <ProfileHeader uid={currentUser.uid} />
                <ProfileButtons onTrophyCaseClick={toggleTrophyCasePopup} />
                <InventoryList
                    uid={currentUser.uid}
                    onSelectItem={handleInventoryItemSelect}
                    selectedInventoryItems={selectedInventoryItems}
                />
                <SwapButton page="profile" onSwap={handleSwap} />
                <DuffelBag
                    uid={currentUser.uid}
                    onSelectItem={handleDuffelBagItemSelect}
                    selectedDuffelItems={selectedDuffelItems}
                />
            </div>
        </div>
    );
};

export default Profile;
