import React, { useState } from "react";
import { auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const signUp = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");

      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //console.log(userCredential);
      })
      .catch((error) => {
        //console.log(error);
        if(error.code === 'auth/email-already-in-use') {
          setMessage("Email is already in use.");
        }
        else {
          setMessage("An error occurred. Please try again later.");
        }
      });
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="bg-[#F9FAFB] h-screen w-screen flex items-center">
      <div className="h-max mx-auto flex flex-col items-center">
        <img
          className="h-[40px] w-[47px] mb-5"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt=""
        />
        <h1 className="text-xl font-bold text-center">
          Create account
        </h1>
        {message && (
          <p
            className={"mt-4 px-4 py-2 rounded-md text-center font-semibold bg-red-100 text-red-700"}
          >
            {message}
          </p>
        )}
        <form
          className="bg-white shadow-xl p-10 flex flex-col gap-4 text-sm"
          onSubmit={signUp}
        >
          <div>
            <label
              className="text-gray-600 font-bold inline-block pb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="border border-gray-400 focus:outline-slate-400 rounded-md w-full shadow-sm px-5 py-2"
              type="email"
              name="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              className="text-gray-600 font-bold inline-block pb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="border border-gray-400 focus:outline-slate-400 rounded-md w-full shadow-sm px-5 py-2"
              type="password"
              name="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
          <label
            className="text-gray-600 font-bold inline-block pb-2"
            htmlFor="confirmPassword"
          >
            Confirm Password
          </label>
          <input
            className="border border-gray-400 focus:outline-slate-400 rounded-md w-full shadow-sm px-5 py-2"
            type="password"
            name="confirmPassword"
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
          <div>
            <input
              className="bg-[#4F46E5] w-full py-2 rounded-md text-white font-bold cursor-pointer hover:bg-[#181196]"
              type="submit"
              value="Create account"
            />
          </div>
          <div className="flex justify-center mt-4">
            <button
              className="px-4 py-2 border flex gap-2 border-slate-400 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-900 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150"
              onClick={googleSignIn}
            >
              <img
                className="w-6 h-6"
                src="https://www.svgrepo.com/show/475656/google-color.svg"
                
                alt="google logo"
              />
              <span>Login with Google</span>
            </button>
          </div>
        </form>
        <p className="text-sm text-gray-500 mt-10">
          Already a scavenger?{" "}
          <span
            onClick={goToLogin}
            className="text-[#4F46E5] font-bold cursor-pointer"
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
