const Admin = () => {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <h1 className="text-4xl font-bold text-gray-800 mb-3">Admin</h1>
        <p className="text-lg text-gray-600">an empty page also</p>
      </div>
    );
  }
  
  export default Admin;
  