import React, { useState } from "react";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const handlePasswordReset = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Check your email to reset your password");
      setMessageType("success");
      setEmail("");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setMessage("Failed to send password reset email");
      setMessageType("error");
    }
  };

  return (
    <div className="bg-[#F9FAFB] h-screen w-screen flex items-center">
      <div className="h-max mx-auto flex flex-col items-center">
        <img
          className="h-[40px] w-[47px] mb-5"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt=""
        />
        <h1 className="text-xl font-bold text-center pb-10">Password reset</h1>
        <div>
          {message && (
            <p
              className={`mt-4 px-4 py-2 rounded-md text-center font-semibold 
                   ${
                     messageType === "success"
                       ? "bg-green-100 text-green-700"
                       : "bg-red-100 text-red-700"
                   }`}
            >
              {message}
            </p>
          )}
        </div>

        <form className="bg-white shadow-xl p-10 flex flex-col gap-4 text-sm">
          <div>
            <label
              className="text-gray-600 font-bold inline-block pb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="border border-gray-400 focus:outline-slate-400 rounded-md w-full shadow-sm px-5 py-2"
              type="email"
              name="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <button
              className="bg-[#4F46E5] w-full py-2 rounded-md text-white font-bold cursor-pointer hover:bg-[#181196]"
              onClick={handlePasswordReset}
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
