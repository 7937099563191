import React, { useState, useEffect } from 'react';

const Spinner = ({ action }) => {
    const [message, setMessage] = useState(action);

    useEffect(() => {
        const maxDots = 3;
        let dotCount = 0;

        const interval = setInterval(() => {
            dotCount = (dotCount + 1) % (maxDots + 1);
            setMessage(action + '.'.repeat(dotCount));
        }, 500); // Update every half second

        return () => clearInterval(interval);
    }, [action]);

    return (
        <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mb-3"></div>
            <p className="text-black font-bold">{message}</p>
        </div>
    );
};
export default Spinner;
