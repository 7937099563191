import React from 'react';
import scavengewarsgraphic from "../assets/scavengewarsgraphic1.png";

const Home = () => {
  return (
    <div className=" h-screen flex flex-col items-center justify-center bg-center bg-no-repeat" 
         style={{ backgroundImage: `url(${scavengewarsgraphic})` }}>
      <h1 className="text-4xl font-bold text-gray-800 mb-3">Home</h1>
      <p className="text-lg text-gray-600">an empty page</p>
    </div>
  );
}

export default Home;
