import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase"; // Import the Firebase auth instance
import { signOut } from "firebase/auth";
import { useAuth } from "../../context/AuthContext";
import { useSocket } from "../../context/SocketContext";

const SignOut = () => {
  const navigate = useNavigate();
  const { username } = useAuth();
  const socket = useSocket();

  const handleSignOut = () => {
    // Emit an event to notify the server about the sign-out
    socket.emit("sign-out", username);

    signOut(auth) // Use the Firebase auth instance
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <button onClick={handleSignOut}><i className="fa-solid fa-arrow-right-from-bracket"></i> Sign Out</button>
  );
};

export default SignOut;
