import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Adjust the import path as needed

const PublicRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (currentUser) {
    // Redirect to home if user is signed in
    return <Navigate to="/" />;
  }

  return children;
};

export default PublicRoute;
