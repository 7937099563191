import React, { useState } from "react";
import ProfileHeader from "../components/profile/ProfileHeader";
import InventoryList from "../components/profile/InventoryList";
import SwapButton from "../components/profile/SwapButton";
import ScavengedItems from "../components/scavenge/ScavengedItems";
import ScavengeButton from "../components/scavenge/ScavengeButton";
import { useAuth } from "../context/AuthContext";
import { useQuery, useMutation } from "react-query";
import {
    fetchUserProfile,
    updateUserEnergy,
    updateUserGold,
    updateInventory,
} from "../services/userProfileService"; // Make sure you have this function
import { queryClient } from "../index";
import ScavengePopup from "../components/scavenge/ScavengePopup";
import Spinner from "../components/utils/Spinner";

const Scavenge = () => {
    const { currentUser } = useAuth();
    const {
        data: userData,
        isLoading,
        error,
    } = useQuery(["userProfile", currentUser.uid], () =>
        fetchUserProfile(currentUser.uid)
    );
    const [isLoadingScavenge, setIsLoadingScavenge] = useState(false);
    const [isScavenging, setIsScavenging] = useState(false);
    const [popupContent, setPopupContent] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [selectedScavengedItems, setSelectedScavengedItems] = useState([]);
    const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);

    // MUTATIONS
    const updateEnergyMutation = useMutation(
        (newEnergy) => updateUserEnergy(currentUser.uid, newEnergy),
        {
            onSuccess: (data) => {
                // Invalidate and refetch user data
                queryClient.invalidateQueries(["userProfile", currentUser.uid]);
            },
            onError: (error) => {},
        }
    );
    const updateGoldMutation = useMutation(
        (gold) => updateUserGold(currentUser.uid, gold),
        {
            onSuccess: () => {
                // Invalidate and refetch user data
                queryClient.invalidateQueries(["userProfile", currentUser.uid]);
            },
            onError: (error) => {
                // Handle error
                console.error("Error updating gold", error);
            },
        }
    );

    const updateInventoryMutation = useMutation(
        (updatedInventory) =>
            updateInventory(currentUser.uid, updatedInventory),
        {
            onSuccess: () => {
                // Invalidate and refetch user data to update the local state
                queryClient.invalidateQueries(["userProfile", currentUser.uid]);
                queryClient.invalidateQueries(["inventory", currentUser.uid]);
                setSelectedScavengedItems([]);
                setSelectedInventoryItems([]);
                setIsScavenging(false);
            },
            onError: (error) => {
                console.error("Error updating inventory", error);
            },
        }
    );

    const handleScavenge = () => {
        if (userData.energy > 0) {
            setIsLoadingScavenge(true); // Start loading
            setTimeout(() => {
                updateEnergyMutation.mutate(1);
                updateGoldMutation.mutate(1);
                setIsLoadingScavenge(false); // End loading after 3 seconds
                setIsScavenging(true);
            }, 2000); // 1.5 seconds delay
        } else {
            setIsPopupOpen(true);
            setPopupContent("You do not have enough energy.");
        }
    };
    // Handle selection for scavenged items
    const handleScavengedItemSelect = (selectedItem) => { 
        if (selectedItem.itemName !== "Empty Slot") {
            console.log(selectedItem);
            setSelectedScavengedItems((prevItems) => {
                if (prevItems.includes(selectedItem)) {
                    return prevItems.filter((item) => item !== selectedItem);
                } else { 
                    return [...prevItems, selectedItem];
                }
            });
        }
    };

    const handleInventoryItemSelect = (selectedItem) => {
        if (selectedItem.itemName !== "Empty Slot" && isScavenging) {
            console.log(selectedItem);
            setSelectedInventoryItems((prevItems) => {
                if (prevItems.includes(selectedItem)) {
                    return prevItems.filter((item) => item !== selectedItem);
                } else {
                    return [...prevItems, selectedItem];
                }
            });
        }
    };

    // Handle the swap
    const handleSwap = () => {
        // Check if no scavenged loot is selected and inventory items are selected
        if(selectedScavengedItems.length === 0) {
            console.log(selectedScavengedItems)
            setPopupContent("No scavenged items selected for swapping.");
            setIsPopupOpen(true);
            return; // Prevent swap operation
        }
        let updatedInventory = userData.inventory || [];

        // Check if the swap would exceed the inventory limit
        if (updatedInventory.length - selectedInventoryItems.length + selectedScavengedItems.length > 6) {
            setPopupContent("Inventory cannot exceed 6 items.");
            setIsPopupOpen(true); // Show the popup with the error message
            return;
        }

        // Remove selected items from inventory
        selectedInventoryItems.forEach((selectedItem) => {
            const index = updatedInventory.findIndex((invItem) =>
                areItemsEqual(invItem, selectedItem)
            );
            if (index !== -1) {
                updatedInventory.splice(index, 1); // Remove the first matching item
            }
        });

        // Add selected scavenged items to inventory
        updatedInventory = [...updatedInventory, ...selectedScavengedItems];

        // Use the mutation to update the inventory
        updateInventoryMutation.mutate(updatedInventory);

        // Reset selected items
        setSelectedScavengedItems([]);
        setSelectedInventoryItems([]);
    };

    // Helper function to check if two items are equal
    // Modify this function to compare the properties that make your items unique
    const areItemsEqual = (item1, item2) => {
        return item1.name === item2.name && item1.quality === item2.quality; // Example comparison
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error fetching user data: {error.message}</div>;

    return (
        <div className="min-h-screen bg-gray-900 sm:p-6">
            <ScavengePopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
            >
                <p>{popupContent}</p>
            </ScavengePopup>
            <div className="max-w-2xl mx-auto bg-amber-500 shadow-md sm:rounded-lg overflow-hidden pb-5 pt-5">
                {isLoadingScavenge ? (
                    <div className="h-screen bg-amber-500 mt-48 text-black">
                        <Spinner action="Scavenging for loot" />
                    </div>
                ) : (
                    <>
                        <div className="hidden sm:block">
                            <ProfileHeader uid={currentUser.uid} />
                        </div>
                        <ScavengeButton onScavenge={handleScavenge} />
                        <ScavengedItems
                            isScavenging={isScavenging}
                            onSelectItem={handleScavengedItemSelect}
                            selectedScavengedItems={selectedScavengedItems}
                        />
                        <SwapButton page="Scavenge" onSwap={handleSwap} />
                        <InventoryList
                            uid={currentUser.uid}
                            onSelectItem={handleInventoryItemSelect}
                            selectedInventoryItems={selectedInventoryItems}
                            scavengePage={true}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Scavenge;
