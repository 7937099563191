
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Authentication 
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import ResetPassword from "./components/auth/ResetPassword";
import SetUsername from "./components/auth/SetUsername";

// Pages
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Scavenge from "./pages/Scavenge";
import NoPage from "./pages/NoPage";
import Profile from "./pages/Profile";
import Chat from "./pages/Chat";
import Admin from "./pages/Admin";

// Routes
import ProtectedRoute from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
import AdminRoute from "./routes/AdminRoute";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="scavenge" element={<ProtectedRoute><Scavenge /></ProtectedRoute>} />
          <Route path="chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
          <Route path="profile" element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
          <Route path="login" element={<PublicRoute><SignIn /></PublicRoute>} />
          <Route path="signup" element={<PublicRoute><SignUp /></PublicRoute>} />
          <Route path="resetpassword" element={<PublicRoute><ResetPassword /></PublicRoute>} />
          <Route path="setusername" element={<ProtectedRoute><SetUsername /></ProtectedRoute>} />
          <Route path="admin" element={<AdminRoute><Admin /></AdminRoute>} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

