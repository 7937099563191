import React, { useState } from "react";
import LootItemDetails from "./LootItemDetails";

const rarityClasses = {
    verycommon: { rarity: "text-verycommon", value: 100 },
    common: { rarity: "text-common", value: 100 },
    uncommon: { rarity: "text-uncommon", value: 100 },
    rare: { rarity: "text-rare", value: 100 },
    veryrare: { rarity: "text-veryrare", value: 100 },
    epic: { rarity: "text-epic", value: 100 },
    legendary: { rarity: "text-legendary", value: 100 },
    artifact: { rarity: "text-artifact", value: 100 },
    heirloom: { rarity: "text-heirloom", value: 100 },
    assembled: { rarity: "text-assembled", value: 100 },
    limited: { rarity: "text-limited", value: 100 },
};

const LootItem = ({ item, onSelectItem, isSelected, scavengePage }) => {
    const {
        quality,
        itemName,
        attack,
        defense,
        rarity,
        research,
        description,
        history,
    } = item;
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const toggleItemDetails = (event) => {
        event.stopPropagation();
        setIsDetailsOpen(!isDetailsOpen);
    };
    const isEmptySlot = itemName === "Empty Slot";
    const rarityClass = rarityClasses[rarity]?.rarity || "";

    const handleItemClick = () => {
        if (!isDetailsOpen) {
            onSelectItem(item); // Pass the whole item to onSelectItem

            console.log("isSelected: " + isSelected);
            console.log("isEmptySlot: " + isEmptySlot);
        }
    };

    const itemClass = `flex items-center justify-between p-2 border-2 border-b-0 last:border-b-2 border-tan Target ${
        isSelected && !isEmptySlot
            ? "bg-brown bg-research-gradient-selected"
            : "bg-research-gradient"
    } ${!isEmptySlot ? "hover:cursor-pointer" : ""}`;
    // Function to determine font size based on item name length
    const getItemNameFontSize = (name) => {
        if (name.length > 35) return "text-xs";
        if (name.length > 30) return "text-sm";
        if (name.length > 25) return "text-md;";
        return "text-lg";
    };
    const calculateStat = (stat, quality) => {
        if (!isNaN(stat) && !isNaN(quality)) {
            return Math.round(stat * (1 + (quality - 1) * 0.2));
        }
        return "?";
    };
    const itemNameFontSize = getItemNameFontSize(itemName);
    const researchPercentage = `${research}%`;
    return (
        <div
            className={`${itemClass} py-1 sm:py-2 relative `}
            onClick={handleItemClick}
            style={{ "--research-percentage": researchPercentage }}
        >
            {isEmptySlot ? (
                <span className="py-1 sm:py-2 flex-1 text-center text-lg sm:text-xl m-1">
                    - {itemName} -
                </span>
            ) : (
                <>
                    <div
                        className={`w-6 sm:w-10 text-tan sm:pl-2 text-sm sm:text-base ${itemNameFontSize}`}
                    >
                        {quality !== undefined ? `Q${quality}` : ""}
                    </div>

                    <span
                        className={`flex-1 ml-2 sm:ml-4 ${rarityClass}  font-semibold [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] truncate drop-shadow-[1px_1px_1px_var(--tw-shadow-color)] shadow-black  ${itemNameFontSize}`}
                    >
                        {itemName}
                    </span>
                    <div className="flex items-center">
                        {quality !== undefined && (
                            <div className="flex space-x-1 text-tan">
                                <div className="flex flex-col text-right font-bold">
                                    <span className="-mb-1">
                                        {calculateStat(
                                            item.attack,
                                            item.quality
                                        )}
                                    </span>
                                    <span>
                                        {calculateStat(
                                            item.defense,
                                            item.quality
                                        )}
                                    </span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="-mb-1 max-sm:hidden">
                                        :Atk
                                    </span>
                                    <span className="-mb-1 sm:hidden">:A</span>
                                    <span className="max-sm:hidden">:Def</span>
                                    <span className="sm:hidden">:D</span>
                                </div>
                            </div>
                        )}
                        {quality !== undefined ? (
                            <button
                                onClick={toggleItemDetails}
                                className="bg-stone-300 border-stone-800 border-4 hover:bg-red-400 ml-1 sm:ml-4 sm:mr-4 p-1 rounded-full h-8 sm:w-12 w-8 flex items-center justify-center"
                            >
                                <span className="text-stone-900 text-sm">
                                    ▶
                                </span>
                            </button>
                        ) : <div className=" h-11"></div>}
                    </div>
                    {isDetailsOpen && (
                        <LootItemDetails
                            isOpen={isDetailsOpen}
                            onClose={toggleItemDetails}
                            item={item}
                            scavengePage={scavengePage}
                        ></LootItemDetails>
                    )}
                </>
            )}
        </div>
    );
};

export default LootItem;
