/******** RETRIEVE FROM DATABASE ********/

// Fetch trophy case
export const fetchUserTrophyCase = async (uid) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/trophycase/${uid}`);

    if (!response.ok) {
        throw new Error('Failed to fetch user trophy case');
    } 
    return response.json();
}


/********** UPDATE DATABASE ********/
export const updateUserTrophyCase = async (uid, username, item) => {
        console.log(uid);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/updateTrophyCase`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: uid, username: username, item: item }),
        }); 
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    
}