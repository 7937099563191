import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import scavengewarslogo from "../../assets/scavengewarslogo.png";

const SetUsername = () => {
    const currentUser = getAuth();
    const [username, setUsername] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [isUsernameSet, setIsUsernameSet] = useState(false);

    const handleReload = () => {
        window.location.reload();
    };

    const checkUsernameExists = async (username) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/checkUsername`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: username,
                    }),
                }
            );

            const data = await response.json();
            return data.exists;
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleSetUsername = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Check if username is between 3 and 15 characters and alphanumeric
        if (!/^[a-zA-Z0-9]{3,15}$/.test(username)) {
            setMessage(
                "Username must be between 3 and 15 characters and letters/numbers."
            );
            setMessageType("error");
            return; // Stop the function if validation fails
        }

        try {
            // Check if username already exists
            if(await checkUsernameExists(username)) { 
              setUsername(""); // reset input field
              throw new Error("Username '" + username + "' already exists");
            }

            // Create a JSON object
            const newUserData = {
                email: currentUser.currentUser.email,
                uid: currentUser.currentUser.uid,
                username: username,
                level: 1,
                gold: 100,
                energy: 20,
                cash: 0,
                inventory: [
                    
                ],
                totalAttack: 0,
                totalDefense: 0,
                duffelBagSize: 0,
                duffelBag: [],
                trophyCase: [],
                trophyCaseScore: 0,
                trophyCaseAvgQuality: 0,
                guild: "",
            };

            // Send to MongoDB
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/setUsername`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    // Send the same userDocRef fields and values to the body
                    body: JSON.stringify(newUserData),
                }
            );
            const data = await response.json();
            console.log(data.message);

            setMessage("Username set successfully to: " + username);
            setMessageType("success");
            setIsUsernameSet(true);

            // Set timeout to 1 second
            setTimeout(() => {
                //sessionStorage.clear();
                window.location.reload();
            }, 2000);
        } catch (error) {
            console.error("Error setting username:", error);
            setMessage(error.message);
            setMessageType("error");
        }
    };

    return (
        <div className="bg-[#F9FAFB] pt-20 sm:h-screen w-screen flex items-center">
            <div className="h-max mx-auto flex flex-col items-center">
                <img
                    className="h-24 w-24 mb-5"
                    src={scavengewarslogo}
                    alt="Scavenge Wars Logo"
                />
                <h1 className="text-xl font-bold text-center">
                    Choose username
                </h1>
                <div>
                    {message && (
                        <p
                            className={`mt-4 px-4 py-2 rounded-md text-center font-semibold 
                   ${
                       messageType === "success"
                           ? "bg-green-100 text-green-700"
                           : "bg-red-100 text-red-700"
                   }`}
                        >
                            {message}
                        </p>
                    )}

                    {isUsernameSet && (
                        <div className="mt-4 px-4 py-2 text-center">
                            <p className="font-semibold text-green-700">
                                Redirecting...{" "}
                                <button
                                    onClick={handleReload}
                                    className="underline text-gray-600 cursor-pointer"
                                >
                                    click here
                                </button>{" "}
                                if not redirected.
                            </p>
                        </div>
                    )}
                </div>
                {!isUsernameSet && ( // Conditionally render the form
                    <form className="bg-white shadow-xl p-10 flex flex-col gap-4 text-sm">
                        <div>
                            <label
                                className="text-gray-600 font-bold inline-block pb-2"
                                htmlFor="username"
                            >
                                Username
                            </label>
                            <input
                                className="border border-gray-400 focus:outline-slate-400 rounded-md w-full shadow-sm px-5 py-2"
                                type="text"
                                name="username"
                                placeholder="Choose your username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div>
                            <button
                                className="bg-[#4F46E5] w-full py-2 rounded-md text-white font-bold cursor-pointer hover:bg-[#181196]"
                                onClick={handleSetUsername}
                            >
                                Confirm
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default SetUsername;
