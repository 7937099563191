import { Outlet, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import SignOut from "../components/auth/SignOut";
import MobileStatusBar from "../components/profile/MobileStatusBar";

const Layout = () => {
    const { currentUser, username } = useAuth();

    return (
        <>
            <MobileStatusBar />

            {/* Main content with padding at the bottom for the mobile navbar */}
            <div className="pt-16 pb-8 sm:pb-0 bg-tan">
                <Outlet />
            </div>

            {/* Navigation bar for larger screens */}
            <nav className="hidden sm:flex bg-gray-800 text-white p-4 fixed inset-x-0 top-0 z-50">
                <div className="container mx-auto flex justify-between items-center">
                    <ul className="flex items-center space-x-4">
                        {currentUser ? (
                            <>
                                <li>
                                    <Link
                                        to="/profile"
                                        className="hover:text-gray-300"
                                    >
                                        Home
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        to="/scavenge"
                                        className="hover:text-gray-300"
                                    >
                                        Scavenge
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/chat"
                                        className="hover:text-gray-300"
                                    >
                                        Arena
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/chat"
                                        className="hover:text-gray-300"
                                    >
                                        Leaderboard
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/chat"
                                        className="hover:text-gray-300"
                                    >
                                        Chat
                                    </Link>
                                </li>
                                {/* Additional links for authenticated users */}
                            </>
                        ) : (
                            <li>
                                <Link to="/" className="hover:text-gray-300">
                                    Home
                                </Link>
                            </li>
                        )}
                    </ul>
                    <ul className="flex items-center space-x-4">
                        {currentUser ? (
                            <>
                                <li className="flex items-center">
                                    <span className="mr-10">
                                        Logged in as{" "}
                                        <span className="font-bold">
                                            {username}
                                        </span>
                                    </span>
                                    <SignOut />
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Link
                                        to="/login"
                                        className="hover:text-gray-300"
                                    >
                                        Login
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/signup"
                                        className="hover:text-gray-300"
                                    >
                                        Sign Up
                                    </Link>
                                </li>
                                {/* Additional links for users who are not authenticated */}
                            </>
                        )}
                    </ul>
                </div>
            </nav>

            {/* Bottom fixed navigation bar for mobile */}
            {currentUser && username && (
                <nav className="sm:hidden text-white p-2 fixed inset-x-0 bottom-0 z-10" style={{ background: 'linear-gradient(to top, #000000 50%, #1a1a1a 50%)' }}>
                    <div className="flex justify-between items-center text-xs">
                        <Link
                            to="/profile"
                            className="flex flex-col items-center justify-center flex-1 text-center hover:text-gray-300 text-lg"
                        >
                            <i className="fa-solid fa-house"></i>{" "}
                            {/* Added padding-bottom here */}
                            <span className="text-xs">Home</span>
                        </Link>

                        <Link
                            to="/scavenge"
                            className="flex flex-col items-center justify-center flex-1 text-center hover:text-gray-300 text-lg"
                        >
                            <i className="fa-solid fa-shovel"></i>
                            <span className="text-xs">Scavenge</span>
                        </Link>
                        <Link
                            to="/arena"
                            className="flex flex-col items-center justify-center flex-1 text-center hover:text-gray-300 text-lg"
                        >
                            <i className="fa-solid fa-swords"></i>
                            <span className="text-xs">Arena</span>
                        </Link>
                        <Link
                            to="/guilds"
                            className="flex flex-col items-center justify-center flex-1 text-center hover:text-gray-300 text-lg"
                        >
                            <i className="fa-solid fa-users"></i>
                            <span className="text-xs">Guilds</span>
                        </Link>

                        <Link
                            to="/profile"
                            className="flex flex-col items-center justify-center flex-1 text-center hover:text-gray-300 text-lg"
                        >
                            <i className="fa-solid fa-square-info"></i>
                            <span className="text-xs">More</span>
                        </Link>
                    </div>
                </nav>
            )}
        </>
    );
};

export default Layout;
