import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import { useSocket } from "../context/SocketContext";

const Chat = () => {
    const [message, setMessage] = useState("");
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [showUsers, setShowUsers] = useState(false);
    const { currentUser, username } = useAuth();
    const socket = useSocket();

    const [messagesLoaded, setMessagesLoaded] = useState(false);
    // Handles the chat messages
    useEffect(() => {
        if (socket) {
            socket.on("user-list", (userList) => {
                setUsers(userList);
            });

            socket.on("message", (receivedMessage) => {
                setChat((prevChat) => [...prevChat, receivedMessage]);
            });

            return () => {
                socket.off("message");
                socket.off("user-list");
            };
        }
    }, [socket]);

    useEffect(() => {
        if (socket && username) {
            socket.emit("new-user", username);
        }
    }, [socket, username]); // Emit "new-user" event only when socket or username changes

    const fetchMessages = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/messages`
            );
            const messages = await response.json();
            setChat(
                messages.map((msg, index) => ({
                    ...msg,
                    id: index, // Since we might not have unique IDs from a text file
                }))
            );
            setMessagesLoaded(true);
        } catch (error) {
            console.error("Error fetching messages:", error);
            // Handle any errors, e.g., by showing an alert to the user
        }
    };
    useEffect(() => {
        if (currentUser) {
            fetchMessages();
        }
    }, [currentUser]);

    // Handles scrolling of the chat
    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (messagesLoaded) {
            const chatContainer = chatContainerRef.current;
            if (chatContainer) {
                chatContainer.scrollTop = chatContainer.scrollHeight;
                setMessagesLoaded(false); // Reset the flag
            }
        }
    }, [messagesLoaded]);

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            // Check if the user is nearly at the bottom of the chat
            const isScrolledToBottom =
                chatContainer.scrollHeight - chatContainer.clientHeight <=
                chatContainer.scrollTop + 50; // 50 is a threshold

            // Scroll to the bottom only if the user is already at the bottom
            if (isScrolledToBottom) {
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }
        }
    }, [chat]);

    const handleMessageChange = (e) => {
        const inputMessage = e.target.value;
        // Limit message to 60 characters
        if (inputMessage.length <= 100) {
            setMessage(inputMessage);
        }
    };
    const sendMessage = async (e) => {
        e.preventDefault();
        if (message) {
            // Emit the message to the socket
            const msgObj = { username: username, text: message };
            socket.emit("message", msgObj);

            // Clear the message input
            setMessage("");
        }
    };

    // Function to convert UTC time to local time and format it
    const formatTime = (utcTime) => {
        const localTime = new Date(utcTime);
        return localTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
    };

    return (
        <div className="p-0 -mt-24 sm:p-4 flex max-w-3xl mx-auto bg-white rounded-lg shadow-md sm:flex-row flex-col">
            <div className="flex-grow bg-gray-100 pt-24">
                <div className="flex justify-end">
                    <button
                        onClick={() => setShowUsers(!showUsers)}
                        className="sm:hidden p-1 mt-2 text-white text-sm bg-blue-500 rounded"
                    >
                        <i className="fa-sharp fa-solid fa-angle-down"></i>{" "}
                        Online Users
                    </button>
                </div>

                <h1 className="text-2xl font-bold mb-3 text-center hidden sm:block">
                    Chatroom
                </h1>
                <div
                    className="bg-gray-100 p-3 h-30rem sm:h-96 overflow-y-auto mb-0 rounded-lg"
                    id="chat-container"
                    ref={chatContainerRef}
                >
                    {chat.map((msg, index) => (
                        <div
                            key={index}
                            className={`flex justify-between items-center p-1 my-0 rounded-md ${
                                msg.text
                                    .toLowerCase()
                                    .includes(username.toLowerCase())
                                    ? "bg-yellow-100"
                                    : "bg-gray-100"
                            }`}
                        >
                            <div className="flex-1 break-all mr-2 max-sm:text-sm">
                                <span
                                    className={`italic ${
                                        msg.username === username
                                            ? "font-bold"
                                            : "font-semibold"
                                    }`}
                                >
                                    {msg.username}
                                </span>
                                : {msg.text}
                            </div>
                            <div className="text-xs sm:text-sm text-gray-500 whitespace-nowrap">
                                {formatTime(msg.timestamp)}
                            </div>
                        </div>
                    ))}
                </div>
                <form onSubmit={sendMessage} className="flex">
                    <input
                        type="text"
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="Type a message"
                        className="flex-grow p-1 border-2 border-gray-200 rounded-l-lg focus:outline-none focus:border-blue-500"
                    />
                    <button
                        type="submit"
                        className="px-4 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600"
                    >
                        Send
                    </button>
                </form>
            </div>
            {/* Online Users */}
            <div className="w-48 ml-4 flex-shrink-0 mt-24 hidden sm:block">
                <h2 className="text-lg font-semibold mb-2">Online Users</h2>
                <ul className="bg-gray-50 p-2 h-96 rounded-lg shadow overflow-y-auto">
                    {users.includes(username) && (
                        <li className="p-1 border-b border-gray-200 font-bold">
                            {username}
                        </li>
                    )}
                    {/* Sort and render the rest of the users */}
                    {users
                        .filter((user) => user !== username) // Exclude the current user
                        .sort() // Sort alphabetically
                        .map((user, index) => (
                            <li
                                key={index}
                                className="p-1 border-b border-gray-200 last:border-b-0"
                            >
                                {user}
                            </li>
                        ))}
                </ul>
            </div>
            {/* Online Users Overlay */}
            <div
                className={`absolute inset-0 bg-black bg-opacity-50 z-10 w-full flex justify-center items-center ${
                    showUsers ? "block" : "hidden"
                } sm:hidden`}
            >
                <div className="bg-white p-4 rounded-lg shadow-lg w-56">
                    <h2 className="text-lg font-semibold mb-2">Online Users</h2>
                    <ul className="overflow-y-auto h-96">
                        {/* Users list */}
                        {users.includes(username) && (
                            <li className="p-1 border-b border-gray-200 font-bold">
                                {username}
                            </li>
                        )}
                        {users
                            .filter((user) => user !== username) // Exclude the current user
                            .sort() // Sort alphabetically
                            .map((user, index) => (
                                <li
                                    key={index}
                                    className="p-1 border-b border-gray-200 last:border-b-0"
                                >
                                    {user}
                                </li>
                            ))}
                    </ul>
                    {/* Centered Close Button */}
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={() => setShowUsers(false)}
                            className="p-2 bg-blue-500 text-white rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
