import LootItem from "./LootItem";
import { fetchUserDuffel } from "../../services/userProfileService";
import { useQuery } from "react-query";

const compareRarity = (rarity1, rarity2) => {
  const rarityOrder = [
      "verycommon",
      "common",
      "rare",
      "veryrare",
      "epic",
      "legendary",
      "heirloom",
      "limited",
  ];
  return rarityOrder.indexOf(rarity2) - rarityOrder.indexOf(rarity1);
};

const DuffelBag = ({ uid, onSelectItem, selectedDuffelItems }) => { 
    const {
        data: items,
        isLoading,
        error,
    } = useQuery(["duffel", uid], () => fetchUserDuffel(uid), {
        enabled: !!uid,
    });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const sortedItems = items.sort((a, b) => {
      const totalA = Math.round((a.attack + a.defense) * (1 + ((a.quality-1) * 0.2)));
      const totalB = Math.round((b.attack + b.defense) * (1 + ((b.quality-1) * 0.2)))
        if (totalA === totalB) {
            return compareRarity(a.rarity, b.rarity);
        }
        return totalB - totalA;
    });

    // Determine the number of empty slots needed
    const emptySlotsCount = 6 - items.length;
    const emptySlots = Array(emptySlotsCount).fill({ itemName: "Empty Slot" });

    return (
        <div className="md:p-6 p-2 pt-0">
            <h2 className="bg-dark-brown text-md sm:text-lg rounded-t-3xl text-center text-tan mx-0.5 py-0 sm:py-2">
                Duffel Bag:
            </h2>
            <div className="bg-unresearched text-white">
                {sortedItems.concat(emptySlots).map((item, index) => (
                    <LootItem
                        key={index}
                        item={item}
                        onSelectItem={onSelectItem}
                        isSelected={Array.isArray(selectedDuffelItems) && selectedDuffelItems.includes(item)}
                    />
                ))}
            </div>
        </div>
    );
};

export default DuffelBag;
